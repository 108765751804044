<template>
  <v-container>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-row>
        <v-col class="mt-0 pt-0 pb-0" cols="12">
          <v-card>
            <v-card-actions>
              <v-icon @click="close">mdi-arrow-left-circle</v-icon>

              <v-spacer></v-spacer>

              <v-btn color="primary" type="submit">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="4" v-if="vm" class="pr-2">
          <v-card>
            <v-card-title class="subheading">Details</v-card-title>
            <v-card-text>
              <v-switch
                v-if="vm.isEnabled!=undefined"
                v-model="vm.isEnabled"
                :label="`Organisation active: ${vm.isEnabled.toString()}`"
              ></v-switch>
              <v-col cols="12">
                <v-text-field
                  v-model="vm.name"
                  label="Organisation Name"
                  v-validate="'required'"
                  data-vv-as="name"
                  name="name"
                  :error-messages="errors.collect('name')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="vm.providerRegistrationNumber"
                  label="Provider Registration Number "
                  v-validate="'required'"
                  data-vv-as="providerRegistrationNumber"
                  name="providerRegistrationNumber"
                  :error-messages="errors.collect('providerRegistrationNumber')"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <address-picker
                  :detail-mode="false"
                  label="Address"
                  :min-characters="4"
                  v-model="vm.address1"
                  v-validate="'required'"
                  required="true"
                  data-vv-as="address1"
                  name="address1"
                  :error-messages="errors.collect('address1')"
                ></address-picker>
              </v-col>

              <v-text-field
                v-model="vm.phonenumber"
                label="phonenumber"
                v-validate="'required'"
                data-vv-as="phonenumber"
                name="phonenumber"
                :error-messages="errors.collect('phonenumber')"
              ></v-text-field>

              <v-col cols="12">
                <v-text-field
                  v-model="vm.abn"
                  label="ABN"
                  data-vv-as="aBN"
                  name="aBN"
                  :error-messages="errors.collect('aBN')"
                ></v-text-field>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8" v-if="currentOrganisation" class="pl-0">
          <v-card>
            <v-card-title class="subheading">Members</v-card-title>
            <v-card-text>
              <users v-bind:org="currentOrganisation"></users>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import users from "../../components/users";
import AddressPicker from "../../components/core/AddressPicker";
Vue.use(VeeValidate);

export default {
  data: () => ({
    vm: null,
    organisations: []
  }),
  mounted() {
    this.init();
  },
  components: {
    users: users,
    AddressPicker
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  methods: {
    init() {
      this.vm =
        this.currentOrganisation == null
          ? { isEnabled: false }
          : this.currentOrganisation;
    },
    close() {
      this.$router.push("/system-admin/organisations");
      setTimeout(() => {
        this.vm = {};
      }, 300);
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$store.dispatch("setCurrentOrganisation", this.vm);
          this.$store.dispatch("setToast", {
            message: "Save success",
            color: "green darken-4"
          });
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    }
  }
};
</script>
